.root {
    padding-top: 80px;
    padding-bottom: 80px;
}

.inner {
    position: relative;
}

.image {
    position: absolute;
    inset: 0;
    opacity: 0.75;
    color: #a6a7ab;
}

.content {
    padding-top: 220px;
    position: relative;
    z-index: 1;

    @media (max-width: 48em) {
        padding-top: 120px;
    }
}

.title {
    font-family:
            Greycliff CF,
            var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: 38px;

    @media (max-width: 48em) {
        font-size: 32px;
    }
}

.description {
    margin: var(--mantine-spacing-xl) auto calc(var(--mantine-spacing-xl) * 1.5);
}