@media print {
    .section {
        display: none;
        &[data-position='left'] {
            display: none;
        }
        &[data-position='right'] {
            display: none;
        }
    }
    .input {
        color: var(--mantine-color-black) !important;
        background-color: var(--mantine-color-white) !important;
        opacity: 1 !important;

        &[data-disabled] {
            color: var(--mantine-color-black) !important;
            background-color: var(--mantine-color-white) !important;
            opacity: 1 !important;
        }
    }
}
