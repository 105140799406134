.App {
    font-family: sans-serif;
    text-align: center;
}

@media print {
    .mantine-DateInput-section {
        display: none;
    }
    #print-input-field {
        background: #ffffff;
    }
    div:has(> svg) {
        display: none;
    }
}
