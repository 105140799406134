.box {
    padding: 50px;
    margin: 50px 140px;
    background-color: #f0effa;
}

.inner-box {
    padding: 25px 50px 50px;
    margin-top: 10px;
    margin-left: -50px;
    margin-right: -50px;
    background-color: #dddbef;
}

.formHidden {
    display: none;
}

@media all {
    .pageBreak {
        display: none;
    }
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .pageBreak {
        display: block;
        page-break-before: always;
        margin-top: 40px;
    }
    label > span {
        visibility: hidden !important;
    }
    .box {
        padding: 50px;
        margin: 10px;
        background-color: #f0effa;
    }
    .printHidden {
        display: none;
    }
    .formHidden {
        display: initial;
    }
    svg {
        display: none;
    }
}

@page {
    size: 210mm 297mm;
}
