.root {
    padding-top: 80px;
    padding-bottom: 120px;
}

.label {
    text-align: center;
    font-weight: 900;
    font-size: 100px;
    line-height: 1;
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
    color: #322886;

    @media (max-width: 48em) {
        font-size: 120px;
    }
}

.title {
    font-family:
            Greycliff CF,
            var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: 38px;

    @media (max-width: 48em) {
        font-size: 32px;
    }
}

.description {
    margin: var(--mantine-spacing-xl) auto calc(var(--mantine-spacing-xl) * 1.5);
}